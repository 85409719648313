import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/me/aaa/github/personalsite/src/components/post_template.js";
import ImageSource from "src/components/image";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Tetris`}</h1>
    <p>{`Source code at `}<a parentName="p" {...{
        "href": "https://github.com/dashed/tetris-sdl-c"
      }}>{`github.com/dashed/tetris-sdl-c`}</a></p>
    <p>{`A simple tetris game implemented in C using SDL 2.x. It was just a toy project to play with and learn SDL 2.x. Developed in 2014.`}<br /></p>
    <small>Scroll down for some GIFs 👇</small>
    <br />
    <br />
    <hr />
    <br />
    <p>{`The original source code was developed in JavaScript within a `}<a parentName="p" {...{
        "href": "https://jsfiddle.net"
      }}>{`jsfiddle`}</a>{` sandbox; and I have written the C version based on it.`}</p>
    <p>{`Unfortunately, I have since lost the original link.`}</p>
    <p>{`However, I have managed to find the piece of code I was working on to encode the tetrominoes and their rotations into a 4x4 grid:`}</p>
    <iframe width="100%" height="300" src="//jsfiddle.net/dashed/xUpTg/embedded/js,css,result/" allowFullScreen="allowfullscreen" frameBorder="0" />
    <p>{`This representation was ported into these lines of code: `}<a parentName="p" {...{
        "href": "https://github.com/dashed/tetris-sdl-c/blob/80e6bad084746663bbe51a8d54c8f028269ae653/src/tetris.h#L62-L96"
      }}>{`https://github.com/dashed/tetris-sdl-c/blob/80e6bad084746663bbe51a8d54c8f028269ae653/src/tetris.h#L62-L96`}</a></p>
    <br />
    <br />
    <hr />
    <br />
    <h3>{`Demo on El Capitan OSX (2017)`}</h3>
    <p><a parentName="p" {...{
        "href": "https://gfycat.com/gifs/detail/CraftyEntireCleanerwrasse"
      }}><img parentName="a" {...{
          "src": "https://thumbs.gfycat.com/CraftyEntireCleanerwrasse-size_restricted.gif",
          "alt": null
        }}></img></a></p>
    <br />
    <h3>{`Demo on Snow Leopard OSX (2014)`}</h3>
    <ImageSource src="tetris/snow-leopard-demo.gif" alt="Tetris Snow Leopard demo" mdxType="ImageSource" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      